import { CurrentUnitVM, UnitInfoVM } from './CurrentUnitVM';
import { SessionStorage } from '../../../models/session/SessionStorage';
import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { Session } from '../../../models/session/Session';
import { Unit } from '../../../models/units/Unit';
import { SelectUnit } from '../../../actions/units/SelectUnit';

export interface CurrentUnitView {
    modelChanged(model: CurrentUnitVM);
}

export class CurrentUnitPresenter {
    private model = new CurrentUnitVM();

    constructor(private view: CurrentUnitView, private sessionStorage: SessionStorage, private actionBus: ActionBus) {
        this.sessionStorage.sessionChanged.subscribe(this, this.showSessionInfo.bind(this));
    }

    async start() {
        const session = await this.sessionStorage.get();
        this.showSessionInfo(session);
    }

    private showSessionInfo(session: Session) {
        this.model.managerName = session?.unit?.manager?.name || '';
        this.model.unitFullAddress = session?.unit?.fullAddress || '';
        this.model.units = session?.user?.units.map(u => this.unitInfoVM(u)) || [];
        this.model.unitNumber = session?.unit?.number?.toString() || '';
        this.view.modelChanged(this.model);
    }

    private unitInfoVM(u: Unit): UnitInfoVM {
        return { id: u.id, fullAddress: u.fullAddress };
    }

    async selectUnit(unitId: number) {
        await this.actionBus.exec(new SelectUnit(unitId));
    }
}
