import React from 'react';
import ReactDOM from 'react-dom';
import './views/base.css';
import Modal from 'react-modal';
import { ReactRouterNavigation } from './views/lib/navigation/reactRouter/ReactRouterNavigation';
import { PresenterFactory } from './views/lib/PresenterFactory';
import AppContext from './views/lib/AppContext';
import { factoryErrorDecorator } from '@eroman/common/src/_base/lang/factoryErrorDecorator';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { AxiosHttpClient } from '@eroman/common/src/_base/http/axiosClient/AxiosHttpClient';
import { Config } from './infrastructure/Config';
import { BrowserDataStorage } from './infrastructure/storage/BrowserDataStorage';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { EventBus } from '@eroman/common/src/_base/eventBus/EventBus';
import { SystemClock } from '@eroman/common/src/_base/time/SystemClock';
import { AuthenticationError } from '@eroman/common/src/_base/http/errors/AuthenticationError';
import { createRoutes } from './Routes';
import { AppRoot } from './views/AppRoot';
import { toast } from 'react-toastify';
import { NetworkError } from '@eroman/common/src/_base/http/errors/NetworkError';
import { HttpFeatureConfigClient } from '@eroman/common/src/lib/featureConfig/HttpFeatureConfigClient';
import { ActionsInitializer } from './infrastructure/ActionsInitializer';
import { WebDeviceInfo } from './infrastructure/WebDeviceInfo';
import { LocalTimeZone } from '@eroman/common/src/_base/time/TimeZone';
import { JsonDateSerializer } from '@eroman/common/src/_base/time/JsonDateSerializer';
import { AnalyticsEventLogger } from './infrastructure/AnalyticsEventLogger';

export class Application {
    private navigator!: ReactRouterNavigation;
    private presenters!: PresenterFactory;
    private actionBus = new ActionBus();
    private eventBus = new EventBus();
    private httpClient = new AxiosHttpClient(Config.get('apiBaseUrl'));
    private sessionStorage = new SessionStorage(new BrowserDataStorage());
    private clock = new SystemClock();
    private featureConfigClient = new HttpFeatureConfigClient(this.httpClient);
    private analyticsLogger = new AnalyticsEventLogger();

    private createPresenterFactory() {
        let factory = new PresenterFactory(this.actionBus, this.navigator, this.eventBus, this.clock, this.sessionStorage, this.featureConfigClient);
        this.presenters = factoryErrorDecorator(factory, this.onUnhandledError.bind(this));
    }

    private initializeAppContext() {
        AppContext.presenters = this.presenters;
    }

    private initializeActions() {
        const actionsInitializer = new ActionsInitializer({
            httpClient: this.httpClient,
            sessionStorage: this.sessionStorage,
            eventBus: this.eventBus,
            deviceInfo: new WebDeviceInfo(),
            jsonDateSerializer: new JsonDateSerializer(new LocalTimeZone()),
            navigator: this.navigator,
        });
        actionsInitializer.initialize(this.actionBus);
    }

    private async isAuthenticated(): Promise<boolean> {
        const session = await this.sessionStorage.get();
        return session.isAuthenticated;
    }

    private async showClaim(): Promise<boolean> {
        const session = await this.sessionStorage.get();
        return session?.user?.units.filter(u => u.id === session.currentUnitId)[0].association.showClaim || false;
    }

    async run() {
        const showClaim = await this.showClaim();
        await this.featureConfigClient.init();
        await this.featureConfigClient.init();
        this.navigator = new ReactRouterNavigation(
            createRoutes(
                this.featureConfigClient,
                showClaim
            ),
            this.isAuthenticated.bind(this),
            this.analyticsLogger
        );
        this.createPresenterFactory();
        this.initializeAppContext();
        this.initializeDevMode();
        this.initializeActions();
        this.initializeModals();
        let rootElement = <AppRoot navigation={this.navigator} />;
        ReactDOM.render(rootElement, document.getElementById('root'));
    }

    private initializeModals() {
        Modal.setAppElement('#root');
    }

    private onUnhandledError(e: Error) {
        switch (true) {
            case e instanceof AuthenticationError: {
                toast.error('Se ha perdido la sesión');
                this.navigator.navigateToAuth();
                break;
            }
            case e instanceof NetworkError: toast.error('Hubo un error en la conexión'); break;
            default: {
                console.log(e);
                toast.error('Ha ocurrido un error. Por favor, intente nuevamente en unos minutos.');
                this.navigator.navigateToHome();
                break;
            }
        }
    }

    private initializeDevMode() {
        if (!DEV) return;
        // @ts-ignore
        window.App = this;
        // @ts-ignore
        window.FeatureConfig = this.featureConfigClient;
    }
}
