export class CurrentUnitVM {
    managerName = '';
    unitFullAddress = '';
    unitNumber = '';
    units: UnitInfoVM[] = [];

    get canSwitchUnit() {
        return this.units.length > 1;
    }
}

export interface UnitInfoVM {
    id: number;
    fullAddress: string;
}
