import { GetUnitPolls } from '@eroman/common/src/actions/polls/GetUnitPolls';
import { VotePoll } from '@eroman/common/src/actions/polls/VotePoll';
import { Poll, PollStates } from '@eroman/common/src/models/polls/Poll';
import { OptionVM, PollsVM, PollVM } from '@eroman/common/src/views/app/Polls/PollsVM';
import { ActionBus } from '@eroman/common/src/_base/actionBus/ActionBus';
import { SessionStorage } from '@eroman/common/src/models/session/SessionStorage';
import { LocalDateTime } from '@eroman/common/src/_base/time/LocalDateTime';

export interface PollsView {
    modelChanged(model: PollsVM);
}

export class PollsPresenter {
    private model = new PollsVM();
    private polls: Poll[] = [];
    constructor(public view: PollsView, public actionBus: ActionBus, public sessionStorage: SessionStorage) {}

    async start() {
        this.set({ isLoading: true });
        let polls = await this.actionBus.query(new GetUnitPolls());
        this.polls = polls;
        let session = await this.sessionStorage.get();
        let unitId = session.currentUnitId;
        this.set({ polls: polls.map(poll => PollVM.fromPoll(poll, unitId)).sort((a, b) => b.id - a.id), isLoading: false });
        this.set({ selectedPoll: this.model.polls[0] ?? null });
    }

    async vote() {
        let selectedPollId = this.model.selectedPoll!.id;
        let votedOption = this.model.selectedPoll!.selectedOption!.label;
        await this.actionBus.exec(new VotePoll(this.model.selectedPoll!.id, this.model.selectedPoll!.selectedOption!.label));
        let polls = await this.actionBus.query(new GetUnitPolls());
        this.polls = polls;
        let session = await this.sessionStorage.get();
        let unitId = session.currentUnitId;
        this.set({ polls: polls.map(poll => PollVM.fromPoll(poll, unitId)).sort((a, b) => b.id - a.id), isLoading: false });
        let updatedPollVM = this.model.polls.find(poll => poll.id === selectedPollId)!;
        updatedPollVM.unitHasAlreadyVoted = votedOption;
        this.set({ selectedPoll: updatedPollVM });
    }

    async selectPoll(pollId: number) {
        let selectedPoll = this.polls.find(poll => poll.id === pollId)!;
        let session = await this.sessionStorage.get();
        let unitId = session.currentUnitId;
        let selectedPollVM = PollVM.fromPoll(selectedPoll, unitId);
        this.set({ selectedPoll: selectedPollVM });
    }

    isSelectedPollEnded() {
        let selectedPoll = this.polls.find(poll => poll.id === this.model.selectedPoll?.id)!;
        return selectedPoll?.endDate?.isBefore(LocalDateTime.now());
    }

    isPollEnded(id: number) {
        let currentPoll = this.polls.find(poll => poll.id === id)!;
        return currentPoll?.endDate?.isBefore(this.now()) || currentPoll?.status == PollStates.CLOSED;
    }

    selectOption(option: OptionVM) {
        let poll = this.model.selectedPoll!;
        poll.selectedOption = option;
        this.set({ selectedPoll: poll });
    }

    private now() {
        let today = new Date();
        let todayTz = new Date(today.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));
        return new LocalDateTime(todayTz.getFullYear(), todayTz.getMonth() + 1, todayTz.getDate(), todayTz.getHours(), todayTz.getMinutes(), 0, 0);
    }

    private set<K extends keyof PollsVM>(changes: Pick<PollsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
