import { Association } from '../associations/Association';
import { ManagerInfo } from '../managers/ManagerInfo';
import { Nullable } from '../../_base/lang/Nullable';

export class Unit {
    constructor(
        public id: number,
        public number: string,
        public apartment: Nullable<string>,
        public association: Association,
        public manager: ManagerInfo,
    ) {}

    get fullAddress() {
        const { street } = this.association.address;
        const apartment = this.apartment ? this.apartment : '';
        const number = this.number ? 'UF ' + this.number : '';

        const identifier = [number, apartment].filter(Boolean).join(' - ');

        return identifier ? `${street} - ${identifier}` : street;
    }


    static fromJson(json: any): Unit {
        return new Unit(
            json.id,
            json.number,
            json.apartment,
            Association.fromJson(json.association),
            ManagerInfo.fromJson(json.manager),
        );
    }
}
